<template>
    <label :class="labelClasses">
        {{ label }}
        <input class="ui-checkbox-input"
               type="checkbox"
               :id="id"
               :name="name"
               :value="reformatValue || 1"
               :disabled="disabled || readonly || !editMode"
               @change="handleChange"
               :checked="isChecked"/>
        <span class="ui-checkbox-icon-wrapper">
            <Icon :is-checked="isChecked"/>
        </span>
    </label>
</template>

<script setup>
    import {computed, defineAsyncComponent, inject, toRefs, watch} from "vue";

    const props = defineProps({
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        labelClass: {
            type: String,
            default: null,
        },
        for: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: "ui-checkbox",
        },
        rightFloat: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: "md",
        },
        name: {
            type: String,
            default: "",
        },
        value: {
            type: [String, Boolean],
            default: "1",
        },
        modelValue: {
            type: [Array, String, Boolean],
            default: "1",
        },
        checked: {
            type: Boolean,
            default: false,
        },
        class: {
            type: String
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    });

    const emit = defineEmits(["update:modelValue"]);

    const {value, modelValue} = toRefs(props);

    const theme = window.THEME ?? "default";

    const Icon = defineAsyncComponent(async () => {
        try {
            return await import(`@/themes/${theme}/vue/components/form-ui/checkbox/Icon.vue`);
        } catch (error) {
            return await import("@/vue/components/form-ui/checkbox/Icon.vue");
        }
    });

    const labelClasses = computed(() => [
        `ui-checkbox size-${props.size}`,
        {
            disabled: props.disabled || props.readonly || !editMode.value,
            "right-float": props.rightFloat,
            "without-label": !props.label,
            [props.labelClass]: !!props.labelClass,
        },
    ]);

    const isChecked = computed(() => {
        if (Array.isArray(modelValue.value)) {
            return modelValue.value.includes(reformatValue.value || "1");
        }

        if (!modelValue.value) return false;

        return modelValue.value === (reformatValue.value || "1");
    });

    const reformatValue = computed(() => {
        if (typeof value.value === "boolean") {
            return value.value ? "1" : "0";
        }

        return value.value;
    });

    const injectedEditMode = inject("editMode", null);

    const editMode = computed(() => {
        if (injectedEditMode) {
            if (props.readonly) {
                return false;
            }

            return injectedEditMode.editModeIn.value;
        }

        return true;
    });

    watch(editMode, () => {
        if (!Array.isArray(modelValue.value) && !modelValue.value) {
            emit("update:modelValue", "0");
        }

        if (typeof modelValue.value === "boolean") {
            emit("update:modelValue", modelValue.value ? "1" : "0");
        }
    });

    function handleChange (event) {
        const inputValue = event.target.value || "1";
        console.log("inputValue", inputValue);
        if (Array.isArray(modelValue.value)) {
            const newValue = [...modelValue.value];

            if (event.target.checked) {
                if (!newValue.includes(inputValue)) {
                    newValue.push(inputValue);
                }
            } else {
                const index = newValue.indexOf(inputValue);
                if (index !== -1) {
                    newValue.splice(index, 1);
                }
            }

            emit("update:modelValue", newValue);
        } else {
            emit("update:modelValue", event.target.checked ? inputValue : "0");
        }
    }

    if (!Array.isArray(modelValue.value) && !modelValue.value) {
        emit("update:modelValue", "0");
    }

    if (typeof modelValue.value === "boolean") {
        emit("update:modelValue", modelValue.value ? "1" : "0");
    }
</script>
